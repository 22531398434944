import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/tailwind.css'
import './assets/css/global.css'
import './assets/css/fonts.css'
import './auth'
import i18n from './i18n'
import Vuelidate from '@vuelidate/validators'
//import Vuelidate from 'vuelidate';
import axios from 'axios';

axios.defaults.baseURL= `https://jublia-ca-dev.herokuapp.com`;
const token = sessionStorage.getItem('user-token')

if (token) {
  axios.defaults.headers.common['Authorization'] = token
}
createApp(App).use(i18n).use(store).use(router).mount('#app')
