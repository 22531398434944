import { createStore } from 'vuex'
import firebase from '@/firebase';
import axios from 'axios';
import Vue from 'vue';
//export default createStore({
  const state ={
    token: sessionStorage.getItem('user-token')||'',
    status: '',
    msg: '',
    userProfile:sessionStorage.getItem('user') ,
    loggedIn: false,
    loggedType: '',
    uri: sessionStorage.getItem('uri')||null
  };
  const getters = {
    isAuthenticated: state =>  !!state.token ,
    //isAuthenticated: state =>  true,
    authStatus: state => state.status,
    msg: state => state.msg,
    userProfile: ({userProfile}) => userProfile,
  };
  const mutations =  {
    AUTH_REQUEST (state) {
      state.status = 'loading'
    },
    setUrl(state, p) {
      state.uri = p
      sessionStorage.setItem('uri',p)
     
    },
   
    AUTH_SUCCESS(state, token)  {
      state.status = 'success'
      if(token)state.token = token;

    },
    AUTH_ERROR(state,err)  {
      if(err)state.status = 'error';
      state.msg = err
    },
    AUTH_ERROR_Del(state,err)  {
     state.status = '';
      state.msg = ''
    },
    USER_DETAILS(state, userProfile) {
      
      state.userProfile = {
        id:userProfile._id || '',
        name: userProfile.firstName || '',
        last: userProfile.lastName || '',
        email: userProfile.email || '',
        phone: userProfile.phone || '',
        picture: userProfile.photoURL|| '',
        smsRefill: userProfile.smsRefill||false,
        smsRemaind: userProfile.smsRemaind|| false,
        emailRefill: userProfile.emailRefill,
        emailRemaind: userProfile.emailRemaind,
        uri: userProfile.uri|| '',
        dateUser: userProfile.dateUser|| ''
      };
      sessionStorage.setItem('user',JSON.stringify(state.userProfile))
   
    },
    LOGOUT(state ) {
     
      state.userProfile = {};
      state.loggedIn = false
    
    }
  };
  const actions =  {
    async editUser({commit, dispatch,state},user, a) {
     
       await axios({url: `/user/update`, data: user, method: 'PUT' })
      .then(resp => {
        commit('USER_DETAILS', resp.data.data)
      
        commit('AUTH_SUCCESS')
      }).catch(err => {
        commit('AUTH_ERROR', err)
       
      });
     
    },
    USER_DETAILS({ commit },user) {
      commit("USER_DETAILS", user);
  },
    async deleteUser({commit, dispatch,state,getters},id) {
    

     await axios({url: `/user/${id}`, method: 'DELETE' })
    .then(resp => {
       commit('AUTH_SUCCESS')

      dispatch('AUTH_LOGOUT')
     
    }).catch(err => {
      commit('AUTH_ERROR', err)
     
    });
   
  },
 
    async registration({commit, dispatch},user) {

    
      

      await axios({url: `/user/signup`, data: user, method: 'POST' })
      .then(resp => {
        const token = resp.data.token
        localStorage.setItem('user-token', token)
       
         commit('USER_DETAILS',  resp.data.data)
         commit('setUrl', resp.data.data.uri)
         sessionStorage.setItem('rid','user');
       
        commit('AUTH_SUCCESS')
       
    }).catch(err => {
      commit('AUTH_ERROR', err)
     
    });
   
  },
  async updateSms({commit, dispatch},user) { 
  

    await axios({url: `/user/updateSms`, data: user, method: 'POST' })
    .then(resp => {
     
       commit('USER_DETAILS',  resp.data.data)
    
     
      commit('AUTH_SUCCESS')
    
    commit('AUTH_ERROR', err)
  });
 
},

  async updateEmail({commit, dispatch},user) { 
  
    await axios({url: `/user/updateEmail`, data: user, method: 'POST' })
    .then(resp => {
    
       commit('USER_DETAILS',  resp.data.data)

      commit('AUTH_SUCCESS')
  }).catch(err => {
    commit('AUTH_ERROR', err)
   
  });
    
 
},

async updateDate({commit, dispatch},datas) { 
 
  await axios({url: `/user/updateEmailDate`, data:  datas, method: 'POST' })
  .then(resp => {
    
    commit('AUTH_SUCCESS')
}).catch(err => {
  commit('AUTH_ERROR', err)
 
});
 

},
  async providerGoogle({commit, dispatch},store) {

    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      dispatch('AUTH_LOGOUT')
      await firebase.auth().signInWithPopup(provider) 
    } catch(error) {
      console.log(error);
    }
  },
 async login({commit, dispatch,state}, user) {
   let vb=sessionStorage.getItem('vb')||'d'
     dispatch('AUTH_LOGOUT')
   await axios({url:`/user/login`, data: user, method: 'POST' })
   .then(resp => {
    const token = resp.data.token
    sessionStorage.setItem('user-token', token)
    sessionStorage.setItem('rid','user');
    sessionStorage.setItem('vb',vb)
    commit('setUrl', resp.data.data.uri)
    sessionStorage.setItem('schemaSelection',resp.data.data.schemaSelection|| '')
    sessionStorage.setItem('schemaSelection1',resp.data.data.schemaSelection|| 0)
    sessionStorage.setItem('log','first')
    sessionStorage.setItem('setNbMonthsPrescriptedb', resp.data.data.setNbMonthsPrescripted)
    commit('USER_DETAILS',  resp.data.data)
    axios.defaults.headers.common['Authorization'] = token
    commit('AUTH_SUCCESS', resp)
}).catch(err => {
  commit('AUTH_ERROR', err)
  sessionStorage.removeItem('user-token')
})


  },
  AUTH_LOGOUT ({state,commit, dispatch}){
          firebase.auth().signOut();
          sessionStorage.clear();
          state.token=''
          commit('LOGOUT')
          delete axios.defaults.headers.common['Authorization']
          
     
  },
  async loginDin({commit, dispatch,state}, din) {
    let vb=sessionStorage.getItem('vb')||'d'

    dispatch('AUTH_LOGOUT')
  // console.log('goooood', din)
  await axios({url:`/user/din`, data: din, method: 'POST' })
  .then(resp => {
   const token = resp.data.token
  
   sessionStorage.setItem('user-token', token)
   sessionStorage.setItem('vb',vb)
   axios.defaults.headers.common['Authorization'] = token
   commit('AUTH_SUCCESS',token)
}).catch(err => {
 commit('AUTH_ERROR', err)
 sessionStorage.removeItem('user-token')
})
  }  ,
   async initErr({commit, dispatch},store) {
      commit('AUTH_ERROR_Del')
   }
  };
  
//})
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

/*
import { createStore } from 'vuex'
export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
*/