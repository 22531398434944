import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';

firebase.initializeApp({
    apiKey: "AIzaSyCX4QVMEDs9eltKJeKxtFHQXUy_yfWYfVA",
    authDomain: "fir-6a483.firebaseapp.com",
    projectId: "fir-6a483",
    storageBucket: "fir-6a483.appspot.com",
    messagingSenderId: "823289790019",
    appId: "1:823289790019:web:3d5528c37f6aa747029ef6",
    measurementId: "G-P7PYY4TVQX"
});

export default firebase;

//https://firebase.google.com/docs/firestore/security/get-started#auth-required
