 const state = () => ({
  subscribed: true,
  refill:  sessionStorage.getItem('refill')||false,
  refillEmail:  sessionStorage.getItem('refillEmail')||false,
  phone: false
})

 const mutations = {
  initDatas(state, { subscribed, phone }) {
    state.subscribed = subscribed
    state.phone = phone
  },
 setSubscribed(state, subscribed) {
    state.subscribed = subscribed
    sessionStorage.setItem('subscribed', subscribed)
  },
  setRefill(state, subscribed) {
    state.refill = subscribed
    sessionStorage.setItem('refill', subscribed)
  },
  setRefillEmail(state, subscribed) {
    state.refillEmail = subscribed
    sessionStorage.setItem('refillEmail', subscribed)
  },
  setPhone(state, phone) {
    state.phone = phone
    sessionStorage.setItem('phone', phone)
  }
}

 const actions = {
 async init({ commit }) {
    const subscribed = sessionStorage.getItem('subscribed')
   const phone = sessionStorage.getItem('phone')
   await commit('initDatas', { subscribed, phone })
   await sessionStorage.setItem('phone', phone || null)
  await sessionStorage.setItem('subscribed', subscribed || false)
 }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
};