<template>
  <div class="wrapper">
    <div class="wrapper__container">
    <jublia-header />
      <div class="container">
        <jublia-nav-step :trad="'treatment.calculator.title'" />
        <!-- SELECTOR -->
        <div v-if="showSelector">
         <ul class=" list-disc  mx-12" >
           <li  class="li"> <jublia-p class="mb-2">{{
              $t('treatment.calculator.text1')
            }}</jublia-p>
            </li>
          <li class="li">  <jublia-p class="mb-2">{{
              $t('treatment.calculator.text2')
            }}</jublia-p>
            </li>
          <li class="li">  <jublia-p class="mb-2">{{
              $t('treatment.calculator.text3')
            }}</jublia-p>
            </li>
            </ul>
            
           
           <jublia-p class="my-6 text ">  {{ $t('treatment.calculator.text4')}} </jublia-p>
          

          <jublia-select-zone @completed="displayPrescripted"  @fdone="display"/>
        </div>
        <!-- PRESCRIPTION DURATION -->
        <div v-if="showPrescripted">
          <jublia-p class="mb-2 text ">
            {{ $t('treatment.calculator.prescription.text') }}
          </jublia-p>
          <jublia-prescription-duration
            class="my-10 p-2 md:w-1/3 md:text-base w-1/3  mx-auto border border-jublia-blue rounded-lg"
          />
          <jublia-p class="mb-2 text ">
            {{ $t('treatment.calculator.prescription.subText') }}
          </jublia-p>
          <div
          v-if="err"
          class="border border-red-500 px-4 py-2 mt-4 text-red-500 text-base text"
        >
         <p class="text">  {{$t('treatment.calculator.prescription.err') }}</p>
        </div>
          <div class="w-full md:mx-auto md:mx-md:w-4/5 lg:w-2/3">
            <jublia-button
              @click.native="displayResult"
              bgpurple
              class="w-full flex justify-between items-center mt-10"
            >
              <div class="text-xl font-bold flex-grow uppercase">
                {{ $t('treatment.calculator.prescription.calculate') }}
              </div>
            </jublia-button>
          </div>
        </div>
      
        <!-- RESULT -->
        <div v-if="showResult" class="container-result">
          <div v-if="nbDays === 0">
            <jublia-p>{{ $t('treatment.calculator.result.error') }}</jublia-p>
          </div>
          <div v-else>
            <jublia-h3 class="mb-6  title ">{{
              $t('treatment.calculator.result.title')
            }}</jublia-h3>
            <jublia-p class="text">
              {{ $t('treatment.calculator.result.prescriptedStart') }}
               <span class="text-xl DINBold text-black">{{ nbBottles }}</span>
              <span 
                v-html="$t('treatment.calculator.result.prescriptedMiddle')"
              />
              <span class="text-xl  DINBold text-black">{{ nbMonthsPrescripted }}</span>
              <span v-html="$t('treatment.calculator.result.prescriptedEnd')" />
            </jublia-p>
            <jublia-p class="mt-8 text">
              {{ $t('treatment.calculator.result.text') }} 
              <span class="text-xl DINMedium  text-black">
              {{ nbDays }} {{ $t('treatment.calculator.result.days') }}
            </span>
            </jublia-p>
            
          </div>
          <button
            @click="reset"
            class="block text-4 underline cursor-pointer  mt-4"
          >
            {{ $t('common.reset') }}
          </button>
          <jublia-result-zone v-if="nbDays > 0" />
        </div>
       <!-- <router-link
          v-if="showResult && nbDays > 0"
          to="/inscription"
          class="no-underline"
        >-->
          <div class="w-full md:mx-auto md:w-4/5 lg:w-2/3"  v-if="showResult && nbDays > 0">
            <jublia-button
            
             @click="go();"
              bgpurple
              class="w-full flex justify-between items-center"
            >
              <div class="text-xl font-bold flex justify-between items-center mr-3 ">
                {{ $t('common.next') }}
              </div>
               <img
                src="@/assets/icons/next.png"
                alt="next button"
                width="10"
                height="10"
              />
            </jublia-button>
          </div>
        <!--</router-link>-->
        <jublia-breadcrumb :currentStep="step" :totalStep="4" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import JubliaP from '@/components/text/jublia-p'
import JubliaButton from '@/components/button/jublia-button'
import JubliaH3 from '@/components/text/jublia-h3'
import JubliaBreadcrumb from '@/components/breadcrumb/jublia-breadcrumb'
//import JubliaP from '~/components/text/jublia-p'
import JubliaHeader from '@/components/header/jublia-header'
import JubliaNavStep from '@/components/nav-step/jublia-nav-step'
import JubliaSelectZone from '@/components/select-zone/jublia-select-zone'
import JubliaResultZone from '@/components/result-zone/jublia-result-zone'
import JubliaPrescriptionDuration from '@/components/prescription-duration/jublia-prescription-duration'
import {maxLength, minLength} from '@vuelidate/validators'
import { useStore} from 'vuex'

export default {
  components: {
    'jublia-header':JubliaHeader,
    'jublia-nav-step':JubliaNavStep,
   'jublia-select-zone': JubliaSelectZone,
    'jublia-button':JubliaButton,
    'jublia-p':JubliaP,
    'jublia-h3':JubliaH3,
   'jublia-breadcrumb': JubliaBreadcrumb,
    'jublia-result-zone':JubliaResultZone,
   'jublia-prescription-duration': JubliaPrescriptionDuration
  },
  data() {
    return {
      showSelector: true,
       err: false,
      step:1,
      showPrescripted: false,
      showResult: false,
      s:false,
      user: {},

    }
  },
   setup () {
      const store= useStore()
       store.dispatch('treatment/init')
    return { }
  },
 
  computed: {
    ...mapState({
      
      nbDays: (state) => state.treatment.nbDays,
       typeUser: (state) => state.login.loggedType,
      nbBottles: (state) => state.treatment.nbBottles,
      nbMonthsPrescripted: (state) => state.treatment.nbMonthsPrescripted,
     
    }),
     ...mapActions(['treatment/init']),
  },
   created: function () {
   if(!!sessionStorage.getItem('user')){
      this.user= JSON.parse(sessionStorage.getItem('user'))||{}
   }
  
  },

  async fetch({ store }) {
    await store.dispatch('treatment/init')
  },
  methods: {
    ...mapMutations(['treatment/initDatas']),
    ...mapActions({
      greatToesInfected: 'treatment/greatToesInfected',
      smallToesInfected: 'treatment/smallToesInfected'
    }),
    reset({ store }) {
      this.greatToesInfected(0)
      this.smallToesInfected(0)
       
      this.showSelector = true
      this.showPrescripted = false
      this.showResult = false
      this.s = false
      this.step=1
      this.$store.dispatch('treatment/init')
    },
    displayPrescripted() {
      this.showSelector = false
      this.showPrescripted = true
      this.s = false
    },
    display() {
      this.showSelector = false
      this.showPrescripted = false
      this.s = true
      //this.step=2
    },
    go(){
      let a=`/inscription`
      if(this.user.email!=undefined)a=`/step-reminder`
     this.$router.push(a)
    },
    displayResult() {
      if(this.nbMonthsPrescripted){
      this.showSelector = false
      this.showPrescripted = false
      this.showResult = true
      this.err=false
      } else{
        this.err=true
      }
    }
  },
  validations: {
    phoneInput: {
      minLength: minLength(12),
      maxLength: maxLength(12)
    },
    name: {
      minLength: minLength(0),
      maxLength: maxLength(19)
    },
  }
}
</script>
<style lang="postcss">
.selector {
  @apply absolute;
}


.li::marker {
  
  color: #00B4CB;
  
}
.text{
  font-size: 18px;
}
.puce-selected {
  @apply block rounded-full bg-jublia-blue-light text-lg border border-jublia-bluest text-jublia-bluest flex justify-center items-center cursor-pointer;
  width: 20px;
  height: 20px;
  padding-top: 4px;
}
.container {
  min-height: 39rem;
  @media all and (min-width: 768px) {
   
   /*min-height: 35rem;*/
  }
}
</style>
