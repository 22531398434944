<template>
  <h3><slot /></h3>
</template>

<script>
export default {
  name: 'JubliaH3'
}
</script>

<style scoped>
h3 {
  @apply text-lg font-bold;
}
</style>
