import { createRouter, createWebHistory } from 'vue-router'
import video from '../views/video'
import login from '../views/login'
import mycose from '../views/mycos'
import cal from '../views/steps/step-calculator'

import store from '@/store/' 
const routes = [
 {
  path: '/:pathMatch(.*)*',
  redirect: '/din'
  },
  
  {
    path: '/',
    name: 'video',
    component: video,
    meta: { requiresAuth: true },
  },
  {
    path: '/video-personal',
    name: 'video-personal',
    component: () => import(/* webpackChunkName: "menu" */ '../views/video-user.vue'),

    meta: { requiresAuth: true, userAuth: true },
  },
  {
    path: '/video',
    name: 'deep',
    component: video,
  },
  {
    path: '/step-calculator',
    name: 'step-calculator',
    component: cal,
    meta: { requiresAuth: true },
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import(/* webpackChunkName: "menu" */ '../views/menu.vue'),

    meta: { requiresAuth: true, userAuth: true },
  },
  {
    path: '/mycos',
    name: 'mycos',
    component: mycose,
    meta: { requiresAuth: true },
  },
  {
    path: '/inscription',
    name: 'login',
    component: login,
    meta: { requiresAuth: true, userAuth: false },
   /* beforeEnter (to, from, next) {
      
      next('/')
    }*/
  }, 
  {
    path: '/user-settings',
    name: 'user-settings',
    component: () => import(/* webpackChunkName: "user" */ '../views/settings/user.vue'),
    meta: { requiresAuth: true, userAuth: true },
   /* beforeEnter (to, from, next) {
      
      next('/')
    }*/
  }, 
  {
    path: '/settings-remaind',
    name: 'settings-remaind',
    component: () => import(/* webpackChunkName: "settings-remaind" */ '../views/settings/settings-remaind.vue'),
    meta: {  requiresAuth: true, userAuth: true },
   /* beforeEnter (to, from, next) {
      
      next('/')
    }*/
  }, 
  {
    path: '/result',
    name: 'result',
    component: () => import(/* webpackChunkName: "result" */ '../views/settings/result.vue'),
    meta: { requiresAuth: true, userAuth: true },
   /* beforeEnter (to, from, next) {
      
      next('/')
    }*/
  }, 
  {
    path: '/sms-settings',
    name: 'sms-settings',
    component: () => import(/* webpackChunkName: "sms-settings" */ '../views/settings/sms-settings.vue'),
    meta: {  requiresAuth: true, userAuth: true },
   /* beforeEnter (to, from, next) {
      
      next('/')
    }*/
  },
  {
    path: '/email-settings',
    name: 'email-settings',
    component: () => import(/* webpackChunkName: "email-settings" */ '../views/settings/email-settings.vue'),
    meta: {  requiresAuth: true, userAuth: true },
   /* beforeEnter (to, from, next) {
      
      next('/')
    }*/
  },
  {
    path: '/din/',
    name: 'din',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
   
    component: () => import(/* webpackChunkName: "din" */ '../views/din.vue')
  },
  {
    path: '/din/:token',
    name: 'dint',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "din" */ '../views/din.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    meta: { requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "privacy" */ '../views/privacy.vue')
  }
  ,
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    meta: { requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "terms-of-use" */ '../views/terms-of-use.vue')
  },
  {
    path: '/ressource',
    name: 'ressource',
    meta: { requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ressource" */ '../views/ressource.vue')
  },
  {
    path: '/step-reminder',
    name: 'reminder',
    meta: {  requiresAuth: true, userAuth: true },
    component: () => import(/* webpackChunkName: "step-reminder" */ '../views/steps/step-reminder.vue')
  },
  
  {
    path: '/step-treatment-progress',
    name: 'step-treatment-progress',
    meta: {  requiresAuth: true, userAuth: true },
    component: () => import(/* webpackChunkName: "step-treatment-progress" */ '../views/steps/step-treatment-progress.vue')
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "contact-us" */ '../views/contact-us.vue')
  },
  {
    path: '/error-form-treatment',
    name: 'error-form-treatment',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "error-form-treatment" */ '../views/error-form-treatment.vue')
  },
  
  {
    path: '/survey',
    name: 'survey',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "survay" */ '../views/survey.vue')
  },
  {
    path: '/survey-personal',
    name: 'survey-personal',
    meta: {  requiresAuth: true, userAuth: true },
    component: () => import(/* webpackChunkName: "'survey-personal" */ '../views/survey-user.vue')
  },
  {
    path: '/about',
    name: 'about',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "aboute" */ '../views/about.vue')
  },
  
  {
    path: '/:userId/upload',
    name: 'upload',
    
    component: () => import(/* webpackChunkName: "upload" */ '../views/upload/index.vue')
  },
  {
    path: '/deep/:name',
    name: 'deep1',
    component: () => import(/* webpackChunkName: "aboute" */ '../views/log.vue'),
   
   
  },
  {
    path: '/:userId/upload-personal',
    name: 'upload-personal',
    meta: {  requiresAuth: true, userAuth: true },
   
    component: () => import(/* webpackChunkName: "upload-personal" */ '../views/upload/upload-user.vue')
  },
  {
    path: '/:userId/step-photo',
    name: 'step-photo',
    meta: { requiresAuth: true },
   
    component: () => import(/* webpackChunkName: "step-photo" */ '../views/upload/step-photo.vue')
  },
 
]

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition;

    }

     if (to.hash) {
        return { selector: to.hash };
    }
return { x: 0, y: 0 }
},
  routes
})

router.beforeEach((to, from, next) => {
  let role = sessionStorage.getItem('rid');
  //console.log(store.getters['login/isAuthenticated'],to.name,role)
  if (to.matched.some(record => record.meta.requiresAuth)) {

    let authenticated = store.getters['login/isAuthenticated'];

    if (!authenticated) {
      next({
        path: '/din',
      })
    } else {
      if (to.meta.userAuth) {
        if (role === "user") {
        return next();
        }else {
          next({
            path: '/',
          })
        }
      }
      next()
    }
  } else {
    
    next()
    
  }
 
})


export default router
