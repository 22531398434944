<template>
  <div style="min-width: 150px; padding-left: 19px;" class="flex lex justify-content text justify-center items-center">
        <span>{{ $t('treatment.calculator.prescription.months') }}</span>
    <select
      id="nbMonths"
      v-model="nbMonths"
      @change="nbMonthsPrescripted(parseInt(nbMonths))"
      name="nbMonths"
      style="cursor: pointer;"
      class="mx-2"
    >
      <option disabled>-</option>
      <option value="12">12</option>
      <option value="11">11</option>
      <option value="10">10</option>
      <option value="9">9</option>
      <option value="8">8</option>
      <option value="6">6</option>
      <option value="7">7</option>
      <option value="5">5</option>
      <option value="4">4</option>
      <option value="3">3</option>
      <option value="2">2</option>
      <option value="1">1</option>
    </select>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
   name: 'jublia-prescription-duration',
  data: () => ({
    nbMonths: 0
  }),
  methods: {
    ...mapActions({
      nbMonthsPrescripted: 'treatment/nbMonthsPrescripted'
    })
  }
}
</script>
