<template>
  <div class="wrapper-menu">
    <div class="wrapper__container">
    <div class="container-menu">
      <div class="pt-4 px-4">
        <div class="flex justify-between">
          <jublia-h1 class="flex-grow text-center DINBold text-jublia-bluest">MENU</jublia-h1>
          <icon-close
            @click.native="$emit('close')"
            class="w-6 h-6 cursor-pointer"
          />
        </div>
      <ul class="mt-4">
          <li
            v-if=" getLocale()  == 'fr'"
            @click="switchLng('en')"
            class="cursor-pointer ml-4 text-lg font-bold mb-8 DINBold text-jublia-gray"
          >
            {{ $t('common.english') }}
          </li>
          <li
            v-else
            @click="switchLng('fr')"
            class="cursor-pointer ml-4 text-lg font-bold mb-8 DINBold text-jublia-gray"
          >
            {{ $t('common.french') }}
          </li>
       
          <li class="mt-2 ml-4 text-lg ">
             <router-link to="/"
              @click.native="$emit('close')"
              class="no-underline DINBold text-jublia-bluest"
            >
             {{ $t('menu.apply') }}
            </router-link>
          </li>
           <li class="mt-2 ml-4 text-lg ">
             <router-link to="/step-calculator"
              @click.native="$emit('close')"
              class="no-underline DINBold text-jublia-bluest"
            >
             {{ $t('menu.calculator') }}
            </router-link>
          </li>
          <li class="mt-2 ml-4 text-lg ">
             <router-link to="/error-form-treatment"
              @click.native="$emit('close')"
              class="no-underline DINBold text-jublia-bluest"
            >
             {{ $t('menu.reminders') }}
            </router-link>
          </li>
           <li class="mt-2 ml-4 text-lg ">
             <router-link to="/error-form-treatment"
              @click.native="$emit('close')"
              class="no-underline DINBold text-jublia-bluest"
            >
              {{ $t('menu.progress') }}
            </router-link>
          </li>
           <li class="mt-2 ml-4 text-lg ">
             <router-link to="/about"
              @click.native="$emit('close')"
              class="no-underline DINBold text-jublia-bluest"
            >
               {{ $t('menu.about') }}
            </router-link>
          </li>
          <li class="mt-2 ml-4 text-lg ">
             <router-link to="/mycos"
              @click.native="$emit('close')"
              class="no-underline DINBold text-jublia-bluest"
            >
               {{ $t('menu.mycose') }}
            </router-link>
          </li>
           <li class="mt-2 ml-4 text-lg ">
             <router-link to="/ressource"
              @click.native="$emit('close')"
              class="no-underline DINBold text-jublia-bluest"
            >
              {{ $t('menu.ressources') }}
            </router-link>
          </li>
         <li class="mt-2 ml-4 text-lg" v-if="!!user.email">
             <router-link to="/menu"
              @click.native="$emit('close')"
              class="no-underline DINBold text-jublia-bluest"
            >
              {{ $t('menu.profil') }}
            </router-link>
          </li>
        </ul>
      <!--</div>
      <div class="px-4 mt-2">
        <ul class="mb-8 relative">
          <li class="mb-4 text-lg font-bold">
            <nuxt-link
              :to="localePath('terms-of-use')"
              @click.native="$emit('close')"
              class="no-underline DINBold text-jublia-bluest"
            >
              {{ $t('menu.terms') }}
            </nuxt-link>
          </li>
          <li class="mb-4 text-lg font-bold">
            <nuxt-link
              :to="localePath('privacy')"
              @click.native="$emit('close')"
              class="no-underline DINBold text-jublia-bluest"
            >
              {{ $t('menu.policy') }}
            </nuxt-link>
          </li>
          <li class="mb-4 text-lg font-bold">
            <nuxt-link
              :to="localePath('contact-us')"
              @click.native="$emit('close')"
              class="no-underline DINBold text-jublia-bluest"
            >
              {{ $t('menu.contact') }}
            </nuxt-link>
          </li>
          <li class="text-lg font-bold">
            <nuxt-link
              @click.native="$emit('close')"
              to="/form-din"
              class="no-underline DINBold text-jublia-bluest"
            >
              {{ $t('menu.exit') }}
            </nuxt-link>
          </li>
          <div class="logo">
            <img
              v-if="$store.state.i18n.locale == 'fr'"
              src="~/assets/img/logo_ccpp.png"
              width="100"
              height="45"
            />
            <img
              v-if="$store.state.i18n.locale == 'en'"
              src="~/assets/img/logo_paab.png"
              width="100"
              height="45"
            />
          </div>
        </ul>-->
      <div class="px-4 mt-8">
        <ul class="mb-8 relative">
       
          <li class="mb-2 text-lg ">
             <router-link to="/terms-of-use"
              @click.native="$emit('close')"
              class="no-underline DINMedium text-jublia-bluest"
            >
              {{ $t('menu.terms') }}
            </router-link>
          </li>
          <li class="mb-2 text-lg ">
            <router-link to="/privacy"
              @click.native="$emit('close')"
              class="no-underline DINMedium text-jublia-bluest"
            >
              {{ $t('menu.policy') }}
            </router-link>
          </li>
          <li class="mb-2 text-lg ">
             <router-link to="/contact-us"
              @click.native="$emit('close')"
              class="no-underline DINMedium text-jublia-bluest"
            >
              {{ $t('menu.contact') }}
            </router-link>
          </li>
        
         <!-- <li class="mb-2 text-lg " v-if="!!user.email">
             <router-link to="/menu"
              @click.native="$emit('close')"
              class="no-underline DINMedium text-jublia-bluest"
            >
              {{ $t('menu.profil') }}
            </router-link>
          </li>-->
          <li class="mb-2 text-lg ">
            <router-link
              @click.native="$emit('close'),logout()"
              to="/din"
              class="no-underline DINMedium text-jublia-bluest"
            >
              {{ $t('menu.exit') }}
            </router-link>
          </li>
          
         <div class="logo">
            <img
              v-if=" getLocale()  =='en'"
              src="@/assets/img/PAABeng.png"
              width="100"
              height="45"
            />
            <img
              v-if=" getLocale()  == 'fr'"
              src="@/assets/img/PAABFr.png"
              width="100"
              height="45"
            />
             <img
              v-if=" getLocale()  == ''"
              src="@/assets/img/PAABFr.png"
              width="100"
              height="45"
            />
          </div>
        </ul>
      </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapMutations } from 'vuex'
import IconClose from '@/components/icons/close'
import JubliaH1 from '@/components/text/jublia-h1'
export default {
  components: {
   'close': IconClose,
    'jublia-h1':JubliaH1,
    'icon-close':IconClose
  },
  data() {
    return {
      user: {},

    }
  },
   created: function () {
   // console.log(typeof this.profil)
    //if(!!this.profil){
   if(!!sessionStorage.getItem('user')){
      // console.log( this.profil)
      this.user= JSON.parse(sessionStorage.getItem('user'))||{}
      //console.log('vb',this.user.email)
   }},
  methods: {
    switchLng(lang) {
      this.$emit('change', lang)
    },
    
    logout(){
      this.$store.dispatch('login/AUTH_LOGOUT')
    },
    getLocale() {
      //console.log(this.$i18n.locale)
      return this.$i18n.getLocale()
    },
  }
}
</script>

<style scoped lang="postcss">
.wrapper__container{
 @apply flex  justify-start
}
.wrapper-menu {
 /* @apply h-full w-full fixed bg-white z-20;*/
  @apply w-full fixed top-0  left-0;
    z-index: 99;
  min-height: 100vh;
  @media all and (min-width: 768px) {
    @apply flex items-center justify-center flex-col bg-jublia-gray ;
  }
}

.container-menu {
  @apply h-full w-full flex flex-col justify-between z-20;
  @media all and (min-width: 768px) {
    max-width: 900px;
    margin: 0 auto;
  }
}

.logo {
  @apply absolute bottom-0 right-0;
  margin-bottom: -7px;
}
</style>
