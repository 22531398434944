<template>
  <ul class="flex justify-center w-full mt-6">
    <li v-for="step in totalStep" :index="step" class="px-1">
      <div v-if="step === currentStep" class="puce active" />
      <div v-if="step !== currentStep" class="puce" />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'JubliaBreadcrumb',
  props: {
    currentStep: {
      type: Number,
      required: true
    },
    totalStep: {
      type: Number,
      required: true
    }
  }
}
</script>
<style scoped>
.puce {
  @apply bg-jublia-purple-light;
  height: 8px;
  width: 8px;
  @apply rounded-full;
}
.active {
  @apply bg-jublia-blue;
}
</style>
