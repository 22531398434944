<template>
  <div class="wrapper-popup">
    <div class="overlay" />
    <div class="container-popup"  style="color:#4D4D4D;line-height:18px;font-size: 14.5px;">
      <slot />
      <div class="flex justify-center mt-4 px-0.5">
        <button
          @click="toggleMenu"
          class="bg-jublia-blue text-white text-base font-bold rounded-lg px-4 py-0 mr-4"
        >
          {{ $t('popup.menu') }}
        </button>
        <button v-if="v=='d'"
          @click="goToSurvey"
          class="bg-jublia-blue text-white text-base font-bold rounded-lg px-4 py-0 mr-4"
        >
          {{ $t('popup.next') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
   props: {
    isEnabled:{
      type: String,
      default: "d"
     }
    },
    data() {
    return {
     v: sessionStorage.getItem('vb')||this.isEnabled,
  
    
  }},

   
  methods: {
    goToIndex() {
      this.reset()
      this.$router.push(`/`)
    },
    goToSurvey() {
      this.$router.push(`/survey`)
    },
    reset() {
      this.$router.push(`/din`)
      this.state = {}
    },
    ...mapMutations('menu',['toggleMenu'])
  }
}
</script>

<style scoped>
.wrapper-popup {
  @apply fixed top-0 left-0 w-full h-full flex items-end px-4 pb-10;
  overflow-y: hidden;
}

.container-popup {
  @apply w-full bg-white rounded-lg border-jublia-purple text-center py-6 z-10;
  max-width: 900px;
  margin: auto;
}

.overlay {
  @apply fixed top-0 left-0 w-full h-screen bg-jublia-bluest opacity-75;
}
 button{
    width: 177px;
    height: 38px;
    font-size: 1rem;
    line-height: 13px;
  }
</style>
