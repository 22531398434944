<template>
  <div>
    <!-- LEFT  -->
    <div v-if="showLeft" class="flex flex-col items-center">
      <jublia-h3 class="w-full uppercase font-bold">
        {{ $t('treatment.calculator.leftFoot') }}
      </jublia-h3>
      <ul class="container-selector">
        <li
          v-for="(puce, index) in select.left"
          :key="index"
          @click="puce.selected = !puce.selected"
          :class="`selector ${puce.className}`"
        >
          <span
            v-if="puce.selected"
            @click="decrementToes(puce.id)"
            class="puce-selected"
            >✓</span
          >
          <span v-else @click="incrementToes(puce.id)" class="puce-not-selected"
            >+</span
          >
        </li>
        <img
          src="@/assets/img/foot/left_foot.png"
          alt="Bottle Jublia"
          width="320"
          height="302"
          style="margin-top: 4rem;"
          class="w-full h-full"
        />
      </ul>
      <div @click="showLeft = !showLeft" class="w-full md:w-4/5 lg:w-2/3">
        <!--<jublia-button>
          {{ $t('common.next') }}
        </jublia-button>-->
        <jublia-button
              bgpurple
              class="w-full flex justify-between items-center"
            >
              <div class="text-xl font-bold flex justify-between items-center mr-3 ">
                {{ $t('common.next') }}
              </div>
               <img
                src="@/assets/icons/next.png"
                alt="next button"
                width="10"
                height="10"
              />
            </jublia-button>
      </div>
    </div>
    <!-- RIGHT  -->
    <div v-if="!showLeft" class="flex flex-col items-center">
      <jublia-h3 class="w-full uppercase font-bold">
        {{ $t('treatment.calculator.rightFoot') }}
      </jublia-h3>
      <ul class="container-selector">
        <li
          v-for="(puce, index) in select.right"
          :key="index"
          @click="puce.selected = !puce.selected"
          :class="`selector ${puce.className}`"
        >
          <span
            v-if="puce.selected"
            @click="decrementToes(puce.id)"
            class="puce-selected"
            >✓</span
          >
          <span v-else @click="incrementToes(puce.id)" class="puce-not-selected"
            >+</span
          >
        </li>
        <img
          src="@/assets/img/foot/right_foot.png"
          alt="Bottle Jublia"
          width="320"
          height="302"
          style="margin-top: 4rem;"
          class="w-full h-full"
        />
      </ul>
      <div @click="completedSelector" class="w-full md:w-4/5 lg:w-2/3">
       <jublia-button
              bgpurple
              class="w-full flex justify-between items-center"
            >
              <div class="text-xl font-bold flex justify-between items-center mr-3 ">
                {{ $t('common.next') }}
              </div>
               <img
                src="@/assets/icons/next.png"
                alt="next button"
                width="10"
                height="10"
              />
            </jublia-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import JubliaH3 from '@/components/text/jublia-h3'
import JubliaButton from '@/components/button/jublia-button'

export default {
  name: 'JubliaSelectZone',
  components: {
    'jublia-button':JubliaButton,
  //JubliaH1,
    'jublia-h3':JubliaH3
  },
  data() {
    return {
      showLeft: true,
      select: {
        left: [
          {
            id: 'pl1',
            selected: false,
            className: 'p-l-1'
          },
          {
            id: 'pl2',
            selected: false,
            className: 'p-l-2'
          },
          {
            id: 'pl3',
            selected: false,
            className: 'p-l-3'
          },
          {
            id: 'pl4',
            selected: false,
            className: 'p-l-4'
          },
          {
            id: 'pl5',
            selected: false,
            className: 'p-l-5'
          }
        ],
        right: [
          {
            id: 'pr1',
            selected: false,
            className: 'p-r-1'
          },
          {
            id: 'pr2',
            selected: false,
            className: 'p-r-2'
          },
          {
            id: 'pr3',
            selected: false,
            className: 'p-r-3'
          },
          {
            id: 'pr4',
            selected: false,
            className: 'p-r-4'
          },
          {
            id: 'pr5',
            selected: false,
            className: 'p-r-5'
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      nbGreatToesInfected: (state) => state.treatment.nbGreatToesInfected,
      nbSmallToesInfected: (state) => state.treatment.nbSmallToesInfected
    })
  },
  methods: {
    ...mapActions({
      increment: 'treatment/increment',
      decrement: 'treatment/decrement',
      greatToesInfected: 'treatment/greatToesInfected',
      schemaSelection: 'treatment/schemaSelection'
    }),
    incrementToes(id) {
      if (id === 'pl5' || id === 'pr5') {
        
        this.greatToesInfected(this.nbGreatToesInfected + 1)
       
      } else {
        this.increment()
      }
    },
    decrementToes(id) {
      if (id === 'pl5' || id === 'pr5') {
        this.greatToesInfected(this.nbGreatToesInfected - 1)
      } else {
        this.decrement()
      }
    },
    completedSelector() {
       sessionStorage.setItem('schemaSelection',  this.select)
      this.schemaSelection(this.select)
      this.$emit('completed')
    },
    form() {
      this.$emit('fdone')
    }
  }
}
</script>
<style scoped>
.container-selector {
  @apply flex justify-center relative;
  width: 396px;
  height: 302px;
  overflow: hidden;
}

.puce-not-selected {
  @apply block rounded-full bg-jublia-white border border-jublia-white flex justify-center items-center text-base text-jublia-bluest cursor-pointer;
  width: 20px;
  height: 20px;
  padding-top: 1px;
}

.p-l-1 {
top: 221px;
left: 17px;
}

.p-l-2 {
 top: 173px;
left: 76px;
}

.p-l-3 {
top: 132px;
left: 137px;
}

.p-l-4 {
  top: 89px;
left: 207px;
}

.p-l-5 {
top: 104px;
left: 307px;
}

.p-r-1 {
 top: 220px;
left: 359px;
}

.p-r-2 {
 top: 168px;
left: 302px;
}

.p-r-3 {
top: 131px;
left: 239px;
}

.p-r-4 {
top: 90px;
left: 168px
}

.p-r-5 {
top: 110px;
left: 74px;
}
</style>
