<template>
  <button
  :style="{ backgroundColor:disable? '' : color}"
  :disabled="disable"
    :class="{
      bdgray,
        blu,
        width,
       bdelete
    }"
    class="text-xl font-bold DINRegular no-underline"
  >
 
    <slot style="vertical-align: middle;" />
  </button>
</template>

<script>

export default {
  name: 'JubliaButton',
  props: {
    title: String,
  color: {
      type: String,
      default:' #00b4cb'
    },
    bdgray: {
      type: Boolean,
      default: false
    },
     disable: {
      type: Boolean,
      default: false
    },
     width: {
      type: Boolean,
      default: false
    }
    ,
     bdelete: {
      type: Boolean,
      default: false
    }
    ,
    blu: {
      type: Boolean,
      default: false
    }
  },
  computed: {
      style () {
        return 'background-color: ' + this.color ;
      }
    },
}
</script>

<style scoped>
button {
  @apply flex;
  @apply items-center justify-center;
  @apply w-full;
  @apply px-5;
  @apply py-1 ;
  @apply min-w-full;
  vertical-align: middle;
 /* @apply rounded-full;*/
  @apply rounded-2xl;
  @apply text-jublia-white;
  @apply bg-jublia-blue;
 @apply font-black;
 @apply text-lg;
 font-size: 1.5rem;
}

.bdgray {
  @apply bg-jublia-gray;
  @apply text-white;
 
  background:  #827f7f !important;
}
.blu{
  background: #00B4CB !important;
}
.width{
  
  width: 44%;
  min-width: 47%;
 /* padding-top: 0.1rem;
 padding-bottom: 0.1rem;
  font-size: 1.5rem;*/
}
.bdelete{
  width: 44%;
  min-width: 47%;
  padding-top: 0.1rem;
 padding-bottom: 0.1rem;
  font-size: 1.5rem;
}
</style>
