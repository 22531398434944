<template>
  <div class="wrapper" >
    <div class="wrapper__container"  >
      <jublia-header  />
      <div class="container"  id="terms">
        <section class="mb-8">
        <!-- <jublia-h1 class="mb-6">{{ $t('mycos.title') }}</jublia-h1>
          <jublia-p v-html="$t('mycos.text')" />
           <jublia-p v-html="$t('mycos.text2')" />
            <jublia-h1 v-html="$t('mycos.resourse')" />-->
           
  <jublia-h3 class="DINBold text-jublia-bluest "> {{ $t('home.mycos.title') }} </jublia-h3><br/>
<p   class="title DINMedium mx-0 my-4" style="margin-left:0 !important"> {{ $t('home.mycos.subtitle') }}</p>
<jublia-p class="text" v-html="$t('home.mycos.text')"/>
<br/>

<jublia-p class=" py-8 DINBold text-jublia-bluest" style="border:1px solid #006699;  border-radius: 30px; font-size: 1rem;text-align:center;text-transform: uppercase;" v-html="$t('home.mycos.box')"/>
<br/>
<div class="relative flex w-2/4 my-8 mx-auto justify-between sm:w-4/5">
  <img
            src="@/assets/img/mycos/Asset351.png"
            alt="Bottle Jublia"
            width="100"
            height="100"
            class="bottle"
          />
          <img
           src="@/assets/img/mycos/Asset371.png"
            alt="Bottle Jublia"
            width="100"
            height="100"
            class="bottle mx-4"
          />
          <img
           src="@/assets/img/mycos/Asset361.png"
            alt="Bottle Jublia"
            width="100"
            height="100"
            class="bottle"
          />
</div>
<p class="title DINMedium mx-0 mb-2" style="margin-left:0 !important"> {{ $t('home.mycos.subtitle2') }}</p>
<jublia-p v-html="$t('home.mycos.list1')"/>
<p class="title DINMedium mx-0 mt-4" style="margin-left:0 !important"> {{ $t('home.mycos.subtitle3') }}</p>
       <jublia-p v-html="$t('home.mycos.list2')"/>
          <div class="relative flex w-full  mx-auto  ">
       <jublia-p v-html="$t('home.mycos.list3')"/>
        <img    class="img   h-28   mx-auto"
        style="width: 40%; height:40%;filter: contrast(1.25);mix-blend-mode: multiply;"
                src="@/assets/img/mycos/Asset382.png"
                alt="next button"
                width="134"
                height="93"
              />
          </div>
        <jublia-p v-html="$t('home.mycos.list4')"/>
        <br/>
        <p class="title DINMedium mx-0" style="margin-left:0 !important"> {{ $t('home.mycos.subtitle4') }}</p>
           <jublia-p v-html="$t('home.mycos.list5')"/>
           <div class="relative flex justify-between w-4/5 my-8 lg:mx-auto  sm:mx-auto mt-8" >
           <div class="w-full flex justify-between items-center" @click="toggleMenu">
           <jublia-button>
              <div class="text-xl  DINBold font-bold flex-grow">
                {{ $t('common.menu') }}
              </div>
            
            </jublia-button></div>
            <div  class="w-full DINBold flex justify-between items-center mx-4" @click=" goToRessource">
              <jublia-button  
            
             
            >
              <div class="text-xl DINBold font-bold flex-grow">
                {{ $t('common.ressource') }}
              </div>
              
            </jublia-button></div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>

import { mapMutations, mapState } from 'vuex'
import JubliaHeader from '@/components/header/jublia-header'
import JubliaH1 from '@/components/text/jublia-h1'
import JubliaP from '@/components/text/jublia-p'
import JubliaButton from '@/components/button/jublia-button'
//import Drop from '~/components/dropdown/drop.vue'
import { useI18n } from "vue3-i18n";

export default {
  components: {
    'jublia-header':JubliaHeader,
    'jublia-button':JubliaButton,
    'jublia-h1':JubliaH1,
    'jublia-p':JubliaP,
   // Drop
  },
  mounted: function()
  {
    if (location.hash && location.hash !== '#') {
        const anchorLocation = decodeURIComponent(location.hash)
        const anchorElement = document.querySelector(anchorLocation)
        if (anchorElement && anchorElement.offsetTop)
            window.scrollTo(0, anchorElement.offsetTop)
    }
    // From testing, without a brief timeout, it won't work.
    setTimeout(() => this.scrollFix(this.$route.hash), 1);
  },
computed: {
    ...mapState(
    ['menuOpen']
  ),
},
methods: {
   scrollFix: function(hashbang)
    {
      location.hash = hashbang;
    },
   goToRessource() {
      this.$router.push(`/ressource`)
    },
     ...mapMutations('menu',['toggleMenu']),
     
  }
  }
</script>
<style lang="postcss" scoped>
p{
  font-weight: 500 !important;
}
.img {

  @media all and (min-width: 200px) and (max-width: 767px) {
     /*widtht:134px !important; 
     height:93px !important;*/
  }
}
.li{
  text-align:justify !important;
}
.mx-12 {
    margin-left: 1rem !important;
    margin-right: 0px !important;
}
</style>