<template>
  <div
    :class="{
      white
    }"
    class="container-header"
  >
 
    <svg-open
      v-if="showMenu"
      @click.native="toggleMenu"
      class="sm:w-6 sm:h-6 w-10 h-10 cursor-pointer"
    />
  
   <!-- <router-link to="/login">-->
      <img
        v-if="white && i18n.getLocale() === 'fr'"
        src="@/assets/img/logo_white_fr.png"
        class="logo sm:w-12 sm:h-12 w-20 h-20"
        alt="Logo Jublia"
      />
      <img
        v-if="white && i18n.getLocale() === 'en'"
        src="@/assets/img/logo_white_en.png"
        class="logo sm:w-12 sm:h-12 w-20 h-20"
        alt="Logo Jublia"
      />
      <img
        v-if="!white && i18n.getLocale() === 'fr'"
        src="@/assets/img/logo_colors_fr.svg"
        class="logo sm:w-12 sm:h-12 w-20 h-20"
        alt="Logo Jublia"
      />
      <img
        v-if="!white && i18n.getLocale() === 'en'"
        src="@/assets/img/logo_colors_en.svg"
        class="logo sm:w-12 sm:h-12 w-20 h-20"
        alt="Logo Jublia"
      />
  <!--  </router-link>-->
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import SvgOpen from '@/components/icons/open'
import { useI18n } from "vue3-i18n";

export default {
  components: {'svg-open':SvgOpen },
  props: {
    showMenu: {
      type: Boolean,
      default: true
    },
    white: {
      type: Boolean,
      default: false
    }
  },
   setup() {
    const i18n = useI18n();
    //const getLocal= i18n.getLocale();
  //console.log(i18n.getLocale())
    const setLocale = () => {
      i18n.setLocale(i18n.getLocale() === 'fr' ? 'en' : 'fr');
    //  console.log('ffff',i18n.getLocale())

    };

    return {
      setLocale,
      i18n
    };
  },
  methods: {
    getLocale() {
      //console.log(this.$i18n.locale)
      return this.$i18n.getLocale()
    },
    ...mapMutations('menu',['toggleMenu'])
  }
}
</script>

<style scoped>
.container-header {
  @apply w-full flex justify-between items-center relative py-4 px-4 bg-white;
  max-width: 900px;
  margin: 0 auto 0 auto;
}

.white {
  @apply text-white;
  @apply bg-transparent;
}
</style>
