<template>
  <h1 class="DINBold"><slot /></h1>
</template>

<script>
export default {
  name: 'JubliaH1'
}
</script>

<style scoped>
h1 {
  @apply uppercase;
  @apply text-xl;
  line-height: 1.25rem;
}
</style>
