const dailyAppForGreatToas = 2
const dailyAppForSmallToas = 1
const nbAppByBottle = 380

 const state = () => ({
  nbDays: sessionStorage.getItem('nbDays') || 0,
  nbBottles:  sessionStorage.getItem('nbBottles') ||0,
  nbGreatToesInfected:  0,
  nbSmallToesInfected: 0,
  nbMonthsPrescripted: sessionStorage.getItem('nbMonthsPrescripted') || 0,
  schemaSelection: JSON.parse(sessionStorage.getItem('schemaSelection1'))|| '',
  schemaSelection1: sessionStorage.getItem('schemaSelection')|| ''
})

 const mutations = {
  calculateDays(state) {
    if (state.nbGreatToesInfected === 0 && state.nbSmallToesInfected === 0) {
      state.nbDays = 0
      return
    }

    const nbDailyAppForGreatToas =
      state.nbGreatToesInfected * dailyAppForGreatToas
    const nbDailyAppForSmallToas =
      state.nbSmallToesInfected * dailyAppForSmallToas
    const resultCalcul = Math.round(
      nbAppByBottle / (nbDailyAppForGreatToas + nbDailyAppForSmallToas)
    )
    if (resultCalcul === Infinity) {
      state.nbDays = 0
      return
    }
    //console.log(resultCalcul)
    state.nbDays = resultCalcul
  },
  calculateBottles(state) {
    if (
      state.nbGreatToesInfected === 0 &&
      state.nbSmallToesInfected === 0 &&
      state.nbMonthsPrescripted === 0
    ) {
      state.nbBottles = 0
      return
    }
  
    const nbDailyAppForGreatToas =
      state.nbGreatToesInfected * dailyAppForGreatToas
    const nbDailyAppForSmallToas =
      state.nbSmallToesInfected * dailyAppForSmallToas
    const nbAppPerPrescription =
      (nbDailyAppForGreatToas + nbDailyAppForSmallToas) *
      (state.nbMonthsPrescripted * 30)
    const nbBottlesPerPrescription =nbAppPerPrescription/ nbAppByBottle
    //const resultCalcul =Math.ceil( nbBottlesPerPrescription)
    let v=Math.floor( nbAppPerPrescription/ nbAppByBottle)
    const r=nbAppPerPrescription% nbAppByBottle
    if(r>0) v=v+1;
    //console.log(v)
    const resultCalcul =v
//console.log(nbBottlesPerPrescription, resultCalcul.toFixed(0), resultCalcul, nbAppPerPrescription%nbAppByBottle)
    if (resultCalcul === Infinity) {
      state.nbMonthsPrescripted = 0
      return
    }
    sessionStorage.setItem('nbBottles', resultCalcul)
    state.nbBottles = resultCalcul
  },
  incrementSmallToesInfected(state) {
    state.nbSmallToesInfected += 1
  },
  decrementSmallToesInfected(state) {
    state.nbSmallToesInfected -= 1
  },
  setnbGreatToesInfected(state, nb) {
    state.nbGreatToesInfected = nb
  },
  setnbSmallToesInfected(state, nb) {
    state.nbSmallToesInfected = nb
    //console.log(nb)
  },
  setSchemaSelection(state, selection) {
    //console.log(JSON.stringify( selection), state.schemaSelection)
    state.schemaSelection =  selection
  },
  setNbMonthsPrescripted(state, nbMonths) {
   // console.log(nbMonths)

    state.nbMonthsPrescripted = nbMonths
    sessionStorage.setItem('nbMonthsPrescripted',nbMonths)
   // console.log(state.nbMonthsPrescripted)
  },
    
      initDatas(state, { nbGreatToesInfected, nbSmallToesInfected, nbDays,nbAppPerPrescription, nbBottles}) {
       // console.log(nbGreatToesInfected, nbSmallToesInfected, nbDays, nbAppPerPrescription, nbBottles)
    state.nbGreatToesInfected = nbGreatToesInfected
    state.nbSmallToesInfected = nbSmallToesInfected
    state.nbDays = nbDays
    state.nbMonthsPrescripted= 0
    state.nbBottles=nbBottles

  }
}

 const actions = {
  async init({ commit }) {
   // console.log('init')
    const nbGreatToesInfected = 0
    const nbSmallToesInfected = 0
    const nbMonthsPrescripted = 0
    const nbDays = 0
    let nbBottles = 0
    const schemaSelection = {}

     sessionStorage.setItem('nbGreatToesInfected', nbGreatToesInfected)
     sessionStorage.setItem('nbSmallToesInfected', nbSmallToesInfected)
     sessionStorage.setItem('nbMonthsPrescripted', nbMonthsPrescripted)
     sessionStorage.setItem('nbDays', nbDays)
     sessionStorage.setItem('nbBottles', nbBottles)
     sessionStorage.setItem('schemaSelection', schemaSelection)

    commit('initDatas', {
      nbGreatToesInfected,
      nbSmallToesInfected,
      nbMonthsPrescripted,
      nbDays,
      nbBottles,
      schemaSelection
    })
  },
  async increment({ commit, state }) {
    commit('incrementSmallToesInfected')
    commit('calculateDays')
    await sessionStorage.setItem('nbDays', state.nbDays)
    await sessionStorage.setItem('nbGreatToesInfected', state.nbGreatToesInfected)
    await sessionStorage.setItem('nbSmallToesInfected', state.nbSmallToesInfected)
  },
  async decrement({ state, commit }) {
    if (state.nbSmallToesInfected > 0) {
      commit('decrementSmallToesInfected')
      commit('calculateDays')
      await sessionStorage.setItem('nbDays', state.nbDays)
      await sessionStorage.setItem('nbGreatToesInfected', state.nbGreatToesInfected)
      await sessionStorage.setItem('nbSmallToesInfected', state.nbSmallToesInfected)
    }
  },
  async greatToesInfected({ commit, state }, nb) {
    commit('setnbGreatToesInfected', nb)
    commit('calculateDays')
    await sessionStorage.setItem('nbDays', state.nbDays)
    await sessionStorage.setItem('nbGreatToesInfected', state.nbGreatToesInfected)
    await sessionStorage.setItem('nbSmallToesInfected', state.nbSmallToesInfected)
  },
  async smallToesInfected({ commit, state }, nb) {
    commit('setnbSmallToesInfected', nb)
    commit('calculateDays')
  
    await sessionStorage.setItem('nbDays', state.nbDays)
    await sessionStorage.setItem('nbGreatToesInfected', state.nbGreatToesInfected)
    await sessionStorage.setItem('nbSmallToesInfected', state.nbSmallToesInfected)
  },
  async schemaSelection({ commit, dispatch }, selection) {
  let nb=0,big=0
  selection.left.forEach(element => {
    if (element.selected && element.id === 'pl5') {
     big+=1
    // console.log('big 1')
    }
    if (element.selected &&element.id !== 'pl5' ){
      nb++
     // console.log(nb)
    }
   // console.log(element.id)
  });
  selection.right.forEach(element => {
    if (element.selected &&element.id === 'pr5') {
    //  console.log('big 2')
     big+=1
    }
    if (element.selected &&element.id !== 'pr5'){
      nb++
     // console.log(nb)
    }
    //console.log(element.id)
  });
  //console.log(nb, state.nbSmallToesInfected, big)
  /***
   *  await this.$cookies.set('nbGreatToesInfected', state.nbGreatToesInfected)
  await this.$cookies.set('nbSmallToesInfected', state.nbSmallToesInfected)
   */
 if(nb!== state.nbSmallToesInfected) {dispatch('smallToesInfected',nb);}
  if(big!== state.nbGreatToesInfected)  dispatch('greatToesInfected',big);
  commit('setSchemaSelection', selection)
  //  console.log(JSON.parse(JSON.stringify(selection)))
   sessionStorage.setItem('schemaSelection', JSON.stringify(selection))
   sessionStorage.setItem('schemaSelection1', JSON.stringify(selection))
  },
  async schema({ commit, dispatch }, selection) {
    let nb=0,big=0
    selection.left.forEach(element => {
      if (element.selected && element.id === 'pl5') {
       big+=1
      // console.log('big 1')
      }
      if (element.selected &&element.id !== 'pl5' ){
        nb++
       // console.log(nb)
      }
     // console.log(element.id)
    });
    selection.right.forEach(element => {
      if (element.selected &&element.id === 'pr5') {
      //  console.log('big 2')
       big+=1
      }
      if (element.selected &&element.id !== 'pr5'){
        nb++
       // console.log(nb)
      }
      //console.log(element.id)
    });
   // console.log(nb, state.nbSmallToesInfected, big,sessionStorage.getItem('setNbMonthsPrescriptedb'))
    /***
     *  await this.$cookies.set('nbGreatToesInfected', state.nbGreatToesInfected)
    await this.$cookies.set('nbSmallToesInfected', state.nbSmallToesInfected)
     */
    dispatch('smallToesInfected',nb);
    dispatch('greatToesInfected',big);
    dispatch('nbMonthsPrescripted', sessionStorage.getItem('setNbMonthsPrescriptedb'));
    commit('setSchemaSelection', selection)
    
      //console.log(JSON.parse(JSON.stringify(selection)))
     sessionStorage.setItem('schemaSelection', JSON.stringify(selection))
     sessionStorage.setItem('schemaSelection1', JSON.stringify(selection))
    },
  async nbMonthsPrescripted({ commit }, nbMonths) {
    //console.log(state.nbMonthsPrescripted)
    commit('setNbMonthsPrescripted', nbMonths)
    commit('calculateBottles')
     sessionStorage.setItem('setNbMonthsPrescripted',nbMonths)
     sessionStorage.setItem('setNbMonthsPrescriptedb',nbMonths)
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
};