<template>
  <!--<div class="wrapper bg-white">
    <div class="container container-transparent">
      <jublia-header />
      <div class="container-ins">-->
  <div class="wrapper">
    <div class="wrapper__container">
    <jublia-header />
      <div class="container">
          <jublia-nav-step :trad="'registration.title'" />
       <!-- <jublia-h1 class="DINBold text-jublia-bluest mb-6 uppercase">
          {{ $t("registration.title") }}
        </jublia-h1>
         -->
        <jublia-p class="text DINRegular" v-html="$t('registration.text')" />
        <div class="mt-2 w-full">
          <div class="relative flex flex-col w-full mt-2">
            <div style="margin: auto; margin-bottom: 2rem; width: 74%">
              <!--<router-link to="/step-reminder">-->
                <div
                  style="
                    margin-bottom: 2.5rem;
                    text-align: center;
                    height: auto;
                    padding: 11px;
                    cursor: pointer;
                  "
                  v-on:click="popup=false"
                  class="
                    w-full
                    border border-jublia-blue
                    h-12
                    rounded-lg
                    mt-2
                    px-4
                    text-xl text-jublia-blue
                  "
                >
                  {{ $t("registration.go") }}
                </div>
             <!-- </router-link>-->
              <input
                v-model="first"
                @input="verifyFn($event);"
                v-bind:placeholder="$t('registration.firstName')"
                type="text"
                class="
                  w-full
                  border border-jublia-blue
                  h-12
                  rounded-lg
                  mt-2
                  px-4
                  text-xl
                "
              />
              <input
                v-model="last"
                v-bind:placeholder="$t('registration.lastName')"
                type="text"
                class="
                  w-full
                  border border-jublia-blue
                  h-12
                  rounded-lg
                  mt-2
                  px-4
                  text-xl
                "
              />
              <input
                v-model="email"
                v-bind:placeholder="$t('registration.email')"
                type="text"
                class="
                  w-full
                  border border-jublia-blue
                  h-12
                  rounded-lg
                  mt-2
                  px-4
                  text-xl
                "
              />

              <input
                v-model="password"
                v-bind:placeholder="$t('registration.password')"
                type="password"
                class="
                  w-full
                  border border-jublia-blue
                  h-12
                  rounded-lg
                  mt-2
                  px-4
                  text-xl
                "
              />
              <div
                ref="errorRegistrationEmail"
                v-if="errorRegistrationEmail"
                class="mt-6 border border-red-500 px-4 py-1 mt-2"
              >
                {{ $t("registration.errorExist") }}
              </div>
              <div
                ref="errorRegistration"
                v-if="errorRegistration"
                class="mt-6 border border-red-500 px-4 py-1 mt-2"
              >
                {{ $t("registration.error") }}
              </div>
              <div
                ref="emailerr"
                v-if="emailerr"
                class="mt-6 border border-red-500 px-4 py-1 mt-2"
              >
                {{ $t("common.errEmail") }}
              </div>
              <!-- <div

                 class="mt-6 border border-red-500 px-4 py-1 mt-2"
               >

               </div>
     -->
            </div>
            <jublia-button
              :disable="
                first == '' || last == '' || email == '' || password == ''
              "
              :bdgray="
                first == '' || last == '' || email == '' || password == ''
              "
              v-on:click="login()"
              style="text-transform: uppercase"
              class="relative mt-2 border-jublia-blue"
              type="submit"
            >
              {{ $t("registration.btn") }}
            </jublia-button>
          </div>
           <jublia-popup-end v-if="!popup" >
          <jublia-p  v-html="$t('popup.text2')" class="w-full text text2" />
        </jublia-popup-end>
          <!--<router-link to="/login">
            <jublia-button


             class="relative mt-4 border-jublia-blue"
             type="submit"
           >
            Facebook
           </jublia-button>
         </router-link>
          <jublia-button
          v-on:click="loginGoogle()"
              color="red"
             class="relative mt-4 border-jublia-blue"
             type="submit"
           >
            Google
           </jublia-button>
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import JubliaP from "@/components/text/jublia-p";
import axios from "axios";
import JubliaButton from "@/components/button/jublia-button";
import JubliaH1 from "@/components/text/jublia-h1";
import { useI18n } from "vue3-i18n";
import { reactive, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import JubliaHeader from "@/components/header/jublia-header";
import JubliaNavStep from '@/components/nav-step/jublia-nav-step'
import JubliaPopupEnd from '@/components/popup-end/popup-end'

export default {
  name: "login",

  components: {
    //JubliaButton,
    "jublia-button": JubliaButton,
    "jublia-header": JubliaHeader,
    'jublia-nav-step':JubliaNavStep,
    "jublia-h1": JubliaH1,
    "jublia-p": JubliaP,
    'jublia-popup-end':JubliaPopupEnd,
  },


  setup() {
    const first = ref("");
    const popup = ref(true);
    const last = ref("");
    const email = ref("");
    let emailerr = ref("");

    const errorRegistration = ref(null);
     const errorRegistrationEmail = ref(null);
    const password = ref("");
    const i18n = useI18n();
    const store = useStore();
    const router = useRouter();
    
    const setLocale = () => {
      i18n.setLocale(i18n.getLocale() === "fr" ? "en" : "fr");
    };

    
    const auth = computed(() => store.getters["login/isAuthenticated"]);
    const stat = computed(() => store.getters["login/authStatus"]);
   const schema = computed(() =>store.state.schemaSelection1)
    const emailfn = (e) => {
      email.value === ""
        ? (email.value = "")
        : /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.value)
        ? (emailerr.value = "")
        : (emailerr.value = "has-error");

    
    };
    const verifyFn = (e) => {
     
      if (email.value === "") email.value = "";
      if (password.value === "") password.value = "";
      //if(e.target.value!==password.value) errorcover.value="Password no"
      if (first.value === "") first.value = "";
    };
    function validName(email) {
  if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(email)){
    return false;
  }
  return true;
}
    const login = () => {
     
      let cond=validName(email.value)
    
    emailerr.value=cond
    if(!cond){
      store
        .dispatch("login/registration", {
          firstName: first.value,
          lastName: last.value,
          email: email.value,
          password: password.value,
           lang:localStorage.getItem('lang')||'fr',
          schemaSelection:sessionStorage.getItem('schemaSelection')||'',
         setNbMonthsPrescripted:sessionStorage.getItem('setNbMonthsPrescriptedb')||0
        })
        .then(() => {
           if (store.getters["login/msg"] == "Error: Request failed with status code 409") {
           store.dispatch("login/initErr")
            throw Error('409');

          }
          if (store.getters["login/authStatus"] == "error") {
           store.dispatch("login/initErr")
            throw Error('error');

          }
          
          

         
          if (store.getters["login/authStatus"] === "success") {
                sessionStorage.setItem('log','first')
            router.push({ name: "reminder" });
          }
        })
        .catch((e) => {
          if(e.message=="409")
          errorRegistrationEmail.value = e.message;
           if(e.message=="error")
          errorRegistration.value = e.message;
        });
    }
     
    };
    const loginGoogle = () => {
      store.dispatch("login/providerGoogle").then(() => {
        router.push({ name: "din" });
      });
    };

    return {
      errorRegistration,
      setLocale,
      verifyFn,
      schema,
      first,
      popup,
      last,
      i18n,
      login,
      email,
      emailerr,
      errorRegistrationEmail,
      loginGoogle,
      emailfn,
      password,
 
    };
  },
};
</script>
<style scoped>
.container-ins {
  max-width: 870px;
  margin: 0 auto;
  
}
.text2{
    text-align: center !important;
}
.container-content {
  @apply w-full;
  @apply m-auto;
  @apply pb-8;
  @apply px-8;
  max-width: 900px;

  @media all and (min-width: 767px) {
    max-width: 500px;
  }
}
</style>
