<template>
  <div class="mb-4">
    <div class="flex items-center DINBold text-jublia-bluest">
     
      <jublia-h1 class="flex-grow">{{ $t(trad) }}</jublia-h1>
    </div>

  </div>

</template>
<script>
import JubliaH1 from '@/components/text/jublia-h1'

export default {
  components: { 'jublia-h1':JubliaH1 },
  props: {
    showPrevious: {
      type: Boolean,
      default: true
    },
    trad: {
      type: String,
      default: ''
    }
  }
}
</script>
<style scoped>
.back {
  @apply cursor-pointer mr-2;
  margin-bottom: 4px;
}
</style>
