import Vue from 'vue';
import Vuex from 'vuex';
import login from '@/store/login/login.js'
import treatment from '@/store/treatment/index.js'
import reminder from '@/store/reminder/index.js'

//Vue.use( Vuex );
const state = {
  menuOpen: false
}

 const mutations = {
  toggleMenu(state, closed = true) {
    state.menuOpen =  closed
  }
}
export const menu ={
  namespaced: true,
  state,
  mutations,
 
};
const store = new Vuex.Store({
  modules: {
    menu,
    login,
    treatment,
    reminder
  }
});

export default store;