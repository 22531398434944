import en from './locales/en.json'
import fr from './locales/fr.json'
import { createI18n } from "vue3-i18n";
import Vue from 'vue'
//Vue.use(createI18n);
const i18n = createI18n({
    locale:localStorage.getItem('lang')|| 'en',
    messages:{
    en:en,
    fr:fr
    }
})
export default i18n;