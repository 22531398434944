<template>
  <p
    :class="{
      tiny
    }"
  >
    <slot />
  </p>
</template>

<script>
export default {
  name: 'JubliaP',
  props: {
    tiny: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
p {
  @apply text-base;
 /* @apply font-bold;*/
}

.tiny {
  @apply text-sm;
}
</style>
