<template>
  <div class="w-full flex justify-center">
    <div class="container-result-zone">
     
      <img
        src="@/assets/img/foot/foot.png"
        alt="Selected feet"
        width="310"
        height="141"
      />
      
      <ul>
        <li
          v-for="item in schemaSelection.left"
          :key="item.id"
          :class="`selector ${item.className}`"
        >
       
          <span v-if="item.selected" class="puce-selected">✓</span>
        </li>
        <li
          v-for="item in schemaSelection.right"
          :key="item.id"
          :class="`selector ${item.className}`"
        >
          <span v-if="item.selected" class="puce-selected">✓</span>
        </li>
        <!-- LIST IMAGES ONGLES LEFT -->
      <!-- <li v-if="schemaSelection.left[4].selected" class="selector o-s-l-5">
          <img src="../../assets/img/ongles/malade/LM_05.png" width="30px" />
        </li>
        <li v-if="!schemaSelection.left[4].selected" class="selector o-s-l-5">
          <img src="../../assets/img/ongles/sain/LS_05.png" width="30px" />
        </li>
        <li v-if="schemaSelection.left[3].selected" class="selector o-s-l-4">
          <img src="../../assets/img/ongles/malade/LM_04.png" width="15px" />
        </li>
        <li v-if="!schemaSelection.left[3].selected" class="selector o-s-l-4">
          <img src="../../assets/img/ongles/sain/LS_04.png" width="15px" />
        </li>
        <li v-if="schemaSelection.left[2].selected" class="selector o-s-l-3">
          <img src="../../assets/img/ongles/malade/LM_03.png" width="13px" />
        </li>
        <li v-if="!schemaSelection.left[2].selected" class="selector o-s-l-3">
          <img src="../../assets/img/ongles/sain/LS_03.png" width="13px" />
        </li>
        <li v-if="schemaSelection.left[1].selected" class="selector o-s-l-2">
          <img src="../../assets/img/ongles/malade/LM_02.png" width="12px" />
        </li>
        <li v-if="!schemaSelection.left[1].selected" class="selector o-s-l-2">
          <img src="../../assets/img/ongles/sain/LS_02.png" width="12px" />
        </li>
        <li v-if="schemaSelection.left[0].selected" class="selector o-s-l-1">
          <img src="../../assets/img/ongles/malade/LM_01.png" width="8px" />
        </li>
        <li v-if="!schemaSelection.left[0].selected" class="selector o-s-l-1">
          <img src="../../assets/img/ongles/sain/LS_01.png" width="8px" />
        </li>-->
        <!-- LIST IMAGES ONGLES RIGHT -->
       <!-- <li v-if="schemaSelection.right[4].selected" class="selector o-s-r-5">
          <img src="../../assets/img/ongles/malade/RM_05.png" width="30px" />
        </li>
        <li v-if="!schemaSelection.right[4].selected" class="selector o-s-r-5">
          <img src="../../assets/img/ongles/sain/RS_05.png" width="30px" />
        </li>
        <li v-if="schemaSelection.right[3].selected" class="selector o-s-r-4">
          <img src="../../assets/img/ongles/malade/RM_04.png" width="15px" />
        </li>
        <li v-if="!schemaSelection.right[3].selected" class="selector o-s-r-4">
          <img src="../../assets/img/ongles/sain/RS_04.png" width="15px" />
        </li>
        <li v-if="schemaSelection.right[2].selected" class="selector o-s-r-3">
          <img src="../../assets/img/ongles/malade/RM_03.png" width="13px" />
        </li>
        <li v-if="!schemaSelection.right[2].selected" class="selector o-s-r-3">
          <img src="../../assets/img/ongles/sain/RS_03.png" width="13px" />
        </li>
        <li v-if="schemaSelection.right[1].selected" class="selector o-s-r-2">
          <img src="../../assets/img/ongles/malade/RM_02.png" width="12px" />
        </li>
        <li v-if="!schemaSelection.right[1].selected" class="selector o-s-r-2">
          <img src="../../assets/img/ongles/sain/RS_02.png" width="12px" />
        </li>
        <li v-if="schemaSelection.right[0].selected" class="selector o-s-r-1">
          <img src="../../assets/img/ongles/malade/RM_01.png" width="8px" />
        </li>
        <li v-if="!schemaSelection.right[0].selected" class="selector o-s-r-1">
          <img src="../../assets/img/ongles/sain/RS_01.png" width="8px" />
        </li>-->
      </ul>
    </div>
  </div>
</template>
<script>

import { mapState, mapMutations ,mapActions,useStore} from 'vuex'
export default {
  name: 'JubliaResultZone',

  computed: {
    ...mapState({
      schemaSelection: (state) => state.treatment.schemaSelection
    })
  },
   data() {
    return {
      
      user: {},

    }
  },
   created: function () {
   
   },
  mounted() {
    
     //  console.log('ihenvv', this.schemaSelection.left,JSON.parse(sessionStorage.getItem('schemaSelection1')))
    
 },
}
</script>
<style scoped>
.container-result-zone {
  @apply mt-8 mt-4 relative;
  width: 310px;
  min-width: 310px;
}

.p-l-1 {
 top: 29px;
left: -4px;
}

.p-l-2 {
 top: 9px;
left: 20px;
}

.p-l-3 {
 top: -6px;
left: 44px
}

.p-l-4 {
 top: -22px;
left: 74px;
}

.p-l-5 {
  top: -24px;
  left: 115px;
}

.p-r-1 {
 top: 30px;
left: 296px;
}

.p-r-2 {
  top: 8px;
left: 274px;
}

.p-r-3 {
 top: -7px;
left: 248px;
}

.p-r-4 {
  top: -24px;
left: 216px;
}

.p-r-5 {
  top: -24px;
  left: 174px;
}

.o-s-l-5 {
  top: 0.3rem;
  left: 6.9rem;
  @media all and (min-width: 768px) {
    top: 0.1rem;
    left: 6.1rem;
  }
}

.o-s-l-4 {
  top: 0.5rem;
  left: 4.6rem;
  @media all and (min-width: 768px) {
    left: 4.1rem;
  }
}

.o-s-l-3 {
  top: 1.4rem;
  left: 2.9rem;
  @media all and (min-width: 768px) {
    left: 2.6rem;
    top: 1.3rem;
  }
}

.o-s-l-2 {
  top: 3.3rem;
  left: 1.7rem;
  @media all and (min-width: 768px) {
    left: 1.5rem;
    top: 3rem;
  }
}

.o-s-l-1 {
  top: 5.5rem;
  left: 0.9rem;
  @media all and (min-width: 768px) {
    left: 0.8rem;
    top: 5rem;
  }
}

.o-s-r-5 {
  top: 0.3rem;
  right: 6.9rem;
  @media all and (min-width: 768px) {
    top: 0.1rem;
    right: 6.1rem;
  }
}

.o-s-r-4 {
  top: 0.5rem;
  right: 4.6rem;
  @media all and (min-width: 768px) {
    right: 4.1rem;
  }
}

.o-s-r-3 {
  top: 1.4rem;
  right: 2.9rem;
  @media all and (min-width: 768px) {
    right: 2.6rem;
    top: 1.3rem;
  }
}

.o-s-r-2 {
  top: 3.2rem;
  right: 1.7rem;
  @media all and (min-width: 768px) {
    right: 1.5rem;
    top: 3rem;
  }
}

.o-s-r-1 {
  top: 5.5rem;
  right: 0.85rem;
  @media all and (min-width: 768px) {
    right: 0.8rem;
    top: 5rem;
  }
}
</style>
